
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cocktails from '../Images/cocktails.svg';


function Happy({onClose}) {

  const API_KEY = process.env.REACT_APP_API_KEY;
  const SHEET_ID = process.env.REACT_APP_SHEET_ID;

const [menuItems, setMenuItems] = useState([]);

useEffect(() => {
  // Define the Google Sheets API URL
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/Menu?key=${API_KEY}`;

  // Fetch data from the Google Sheets API
  axios.get(url)
    .then((response) => {
      const data = response.data.values;

      // Parse the data to create an array of menu items
      if (data && data.length > 0) {
        const headers = data[0];
        const menuData = data.slice(1);

        const menuItems = menuData.map((item) => {
          const menuItem = {};
          headers.forEach((header, index) => {
            menuItem[header] = item[index];
          });
          return menuItem;
        });

        setMenuItems(menuItems);
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
}, []);

    const firsItem = menuItems[0];
    const secondItem = menuItems[1];


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="bg-white sm:w-1/2 md:w-1/3 lg:w-1/4 p-4 rounded-lg shadow-lg opacity-100">
            <div>
            <>
          <div className="flex justify-end">
            <button
              className="text-gray-600 hover:text-gray-800"
              onClick={onClose}
            >
              &#x2716;
            </button>
          </div>
          <div className='flex justify-center'>
            <img
              src={cocktails}
              className="h-16 w-auto"
              alt="cocktails icon"
            ></img>
          </div>
          <div className="py-4 flex justify-center">
            <p className="font-bold">{firsItem && firsItem.HappyHour}</p>
          </div>
          <div className='flex justify-center'>
          <p className="font-bold">{secondItem && secondItem.HappyHour}</p>
          </div>
          </>
          </div>
        </div>
        
      </div>
  
            )
  };

export default Happy;