import React, { useEffect, useState } from 'react';
import axios from 'axios';

const API_KEY= process.env.REACT_APP_API_KEY;
const SHEET_ID = process.env.REACT_APP_SHEET_ID;

function WinesW() {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/Menu?key=${API_KEY}`;
        const response = await axios.get(url);
        const data = response.data.values;

        if (data && data.length > 0) {
          const headers = data[0];
          const menuData = data.slice(1);

          const menuItems = menuData.map((item) => {
            const menuItem = {};
            headers.forEach((header, index) => {
              menuItem[header] = item[index];
            });
            return menuItem;
          });

          setMenuItems(menuItems);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


    return (
      <div className="flex justify-center pt-10 pb-10" id='whitewines-section'>
        <div className="p-10 max-w-3xl rounded-3xl bg-Whiteb w-screen bg-contain bg-gray" >
          <h1 className="text-2xl font-semibold mt-10 mb-10 text-center bg-auto text-black">White Wines</h1>
          <ul className="space-y-1 ">
          {menuItems.map((item, index) => (
            <>
          <div className='flex justify-between text-black space-x-10' key={index}>
            <span className='text-black font-mono font-bold'>{item.WhiteWines}</span>
            <div className='flex justify-between text-black space-x-10 '>
            <span className='text-black font-normal '>{item.PriceW}</span>
            <span className='text-black font-normal'>{item.PriceWhiteBottles}</span>
            </div>
            </div>
            </>
          )
          )} 
            {/* {menuItems.map((item, index) => (
              <li key={index} className="flex justify-between">
                <span className='mr-20'>{item.name}</span>
            <span className="font-thin">{item.price}</span> 
            </li>
            ))} */}
          </ul>
        </div>
      </div>
    )
  };

export default WinesW;