import React from 'react';

const MainLayout = ({ children }) => {
  return (
    <div className=" bg-woodbg bg-reverse">
        <main>
      {children}
      </main>
    </div>
  );
};

export default MainLayout;