import React from 'react';

function Footer() {
  return (
    <footer className="flex items-center bg-gray-200 h-32 w-screen">
      <div className="text-center w-full">
        <h1 className="font-bold text-sm md:text-lg">
          Westin Hotels and Resorts • © 2023
        </h1>
      </div>
    </footer>
  );
}

export default Footer;