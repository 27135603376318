
import React, { useEffect, useState } from 'react';
import axios from 'axios';



function Beers() {
const [menuItems, setMenuItems] = useState([]);

const API_KEY = process.env.REACT_APP_API_KEY;
const SHEET_ID = process.env.REACT_APP_SHEET_ID;

useEffect(() => {
  // Define the Google Sheets API URL
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/Menu?key=${API_KEY}`;

  // Fetch data from the Google Sheets API
  axios.get(url)
    .then((response) => {
      const data = response.data.values;

      // Parse the data to create an array of menu items
      if (data && data.length > 0) {
        const headers = data[0];
        const menuData = data.slice(1);

        const menuItems = menuData.map((item) => {
          const menuItem = {};
          headers.forEach((header, index) => {
            menuItem[header] = item[index];
          });
          return menuItem;
        });

        setMenuItems(menuItems);
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
}, []);


    return (
      <div className="flex justify-center pt-10 pb-10" id='beers-section'>
        <div className="p-10 max-w-3xl rounded-3xl w-screen bg-Whiteb bg-contain bg-grey space-y-6 " >
          <h1 className="text-2xl font-semibold mt-10 mb-10 text-center bg-auto text-black" id='beers-section'>Beers</h1>
          <ul className="space">
          {menuItems.map((item, index) => (
            <>
          <div className='flex justify-between text-black space-x-10' key={index}>
            {index === 0 ? (
              <>
            <span className='text-black  font-mono font-bold'>{item.DraftBeer}</span>
            <span className='text-black font-bold'>{item.Pricebeer1}</span>
            </>
            ) : (
          <div className='text-black font-light'>{item.DraftBeer}</div>
            )}
            </div>
            </>
          
          )
          )} 
        
          </ul>
          <ul className="">
          {menuItems.map((item, index) => (
            <>
          <div className='flex justify-between text-black space-x-10' key={index}>
            {index === 0 ? (
              <>
            <span className='text-black font-mono font-bold'>{item.DomesticBeers}</span>
            <span className='text-black font-bold'>{item.Pricebeer2}</span>
            </>
            ) : (
          <div className='text-black font-light'>{item.DomesticBeers}</div>
            )}
            </div>
            </>
          
          )
          )} 
        
          </ul>

          <ul className="">
          {menuItems.map((item, index) => (
            <>
          <div className='flex justify-between text-black space-x-10' key={index}>
            {index === 0 ? (
              <>
            <span className='text-black font-mono font-bold'>{item.ImportedBottles}</span>
            <span className='text-black font-bold'>{item.Pricebeer3}</span>
            </>
            ) : (
          <div className='text-black font-light'>{item.ImportedBottles}</div>
            )}
            </div>
            </>
            
          
          )
          )} 
        
          </ul>
          <ul className="">
          {menuItems.map((item, index) => (
            <>
          <div className='flex justify-between text-black space-x-10' key={index}>
            {index === 0 ? (
              <>
            <span className='text-black font-mono font-bold'>{item.NonAlcoholic}</span>
            <span className='text-black font-bold'>{item.Pricebeer4}</span>
            </>
            ) : (
          <div className='text-black font-light'>{item.NonAlcoholic}</div>
            )}
            </div>
            </>
          
          )
          )} 
        
          </ul>
          <ul className="">
          {menuItems.map((item, index) => (
            <>
          <div className='flex justify-between text-black space-x-10' key={index}>
            {index === 0 ? (
              <>
            <span className='text-black font-mono font-bold'>{item.HardSeltzer}</span>
            <span className='text-black font-bold'>{item.Pricebeer5}</span>
            </>
            ) : (
          <div className='text-black font-light'>{item.HardSeltzer}</div>
            )}
            </div>
            </>
          
          )
          )} 
        
          </ul>
        </div>
      </div>
    )
  };

export default Beers;