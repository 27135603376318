import React from 'react';
import Carousel from './Carousel';

const About = () => {
    return (
        <div className="flex justify-center pt-20 " id='aboutus-section'>
        <div className="max-w-3xl rounded-3xl w-screen bg-white" >
            <div className='p-10'>
          <h1 className="text-4xl font-bold font-mono gh mt-10 mb-10 text-center bg-auto text-black">Half Moon Lounge</h1>
          <p className='font-sans pb-4'>Creative cocktails, light fare, and American cuisine are on the menu at the Half Moon Lounge,
             located in the hotel lobby. Relaxed and a little bit retro, it’s a modern and sophisticated spot to end a day of exploration or grab an aperitif before dinner. Enjoy the natural light pouring in from our expansive windows.</p>
             <Carousel />
             </div>
          </div>
          </div>
    );
};

export default About;