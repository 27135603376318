import React, { useState } from "react";
import cass1 from "../Images/cass1.jpg";
import cass2 from "../Images/cass2.jpg";

const images = [cass1, cass2];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="relative max-w-5xlxl ">
      <div className="overflow-hidden relative h-96 rounded-3xl bg-black">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className={`absolute top-0 left-0 w-full h-full object-none transition-transform duration-500 ease-in-out ${
              index === currentIndex ? "opacity-80" : "opacity-0"
            }`}
          />
        ))}
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center p-4">
        <button
          className="text-white bg-black bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none"
          onClick={prevSlide}
        >
          &#8592;
        </button>
        <button
          className="text-white bg-black bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none"
          onClick={nextSlide}
        >
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default Carousel;

