import Head from './Components/Header'
import Home from './Components/Home';
import Footer from './Components/Footer';
import Menu from './Components/Menu';
import Beers from './Components/Beers';
import WinesR from './Components/WinesR';
import WinesW from './Components/WinesW';
import Champagne from './Components/Champagne';
import Alert from './Components/Alert';
import About from './Components/About';
import React from 'react';
import MainLayout from './layout/MainLayout';

function App() {
  return (
    <MainLayout>
    <div className="App">
    <Head />
    <Alert />
    <About />
    <Home />
    <Menu />
    <Beers />
    <WinesR />
    <WinesW />
    <Champagne />
    <Footer />
    </div>
    </MainLayout>
  );
}

export default App;
