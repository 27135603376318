import { useState } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import logo from '../Images/logo.png';
import { useEffect, useRef } from 'react';
import { handleClick } from '../Hooks/handleClick';

const Header = () => {
  const [previousPos, setPreviousPos] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  //Use Reference Hook for the header to change its style property
  const headRef = useRef(document.getElementById('header-box'))

  //Use Effect hook that is attached to the scroll event
  //When the user scrolls down it will hide the header and when he/she scrolls down it will show the header.
  useEffect(()=>{

    const scrollEvent = ()=>{
      const currentPos = window.scrollY;
      if (previousPos < currentPos ){
        headRef.current.style.transform = `translateY(-200px)`;
      }else{
        headRef.current.style.transform = `translateY(0px)`;
      }
      setPreviousPos(currentPos);

    }

    window.addEventListener("scroll", scrollEvent);

    return (()=>{
      window.removeEventListener("scroll",scrollEvent);
    })

  },[previousPos]);


  return (
    <header className="bg-Whiteb bg-contain top-0 left-0 right-0 translate-y-0 transition-transform duration-150 ease-in-out fixed  z-10" id='header-box' ref={headRef} >
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-5" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5" onClick={handleClick("cocktails")}>
            <span className="sr-only">Half Moon</span>
            <img className="h-auto w-8 rounded-lg object-contain " src={logo} alt="logo" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <a href="/" className="text-sm font-semibold leading-6 text-gray-900">
            Hours
          </a>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
             <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-20 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="" className="-m-1.5 p-1.5"      onClick={handleClick("cocktails")}>
              <span className="sr-only">Half Moon Lounge</span>
              <img
                className="h-16 w-auto"
                src={logo}
                alt=""

              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              id='close-hamburger'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="#cocktails-section"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleClick("cocktails")}
                >
                  Cocktails
                </a>
                <a
                  href="#food-section"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleClick("food")}
                >
                  Bites
                </a>
                <a
                  href="#beers-section"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleClick("beers")}
                >
                  Beers
                </a>
                <a
                  href="#redwines-section"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleClick("redwines")}
                >
                  Red Wines
                </a>
                <a
                  href="#whitewines-section"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleClick("whitewines")}
                >
                  White Wines
                </a>
                <a
                  href="#champagne-section"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleClick("champagne")}
                >
                  Champagne
                </a>
              </div>
              <div className="py-6">
                <a
                  href="#aboutus-section"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleClick("aboutus")}
                >
                  About us
                  </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}


export default Header;