import React, {useState, useEffect} from 'react';
import Happy from './Happy';

function Alert() {
    const [showAlert, setShowAlert] = useState(true);


useEffect(() => {

    const startTime = { hour: 1, minute: 0};
    const endTime = { hour: 23, minute: 0};

    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    const timeUntilStart = (
      (startTime.hour - currentHour)  * 60 * 60 * 1000 +
      (startTime.minute - currentMinute)  * 60 * 1000
    );

    const timeUntilEnd = (
      (endTime.hour - currentHour)  * 60 * 60 * 1000 +
      (endTime.minute - currentMinute) * 60 * 1000
    );

    if (timeUntilStart >= 0 && timeUntilEnd >= 0 ) {
        setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, timeUntilEnd) ;
    }
    
} , []);

    const closeAlert = () => {
        setShowAlert(false);
    }

    return (
        <div className='bg-white'>
          {showAlert && (
            <Happy
              onClose={closeAlert}

            />
          )}
        </div>
      );
    }

    export default Alert;