
const handleClick = (anchor) => () => {
    const id = `${anchor}-section`;
    const element = document.getElementById(id);
    const closeMenu = document.getElementById('close-hamburger')
    if(element) {
        element.scrollIntoView ({
            behavior: "smooth",
            block:"start",
        });

    if(closeMenu) {
        closeMenu.click();
    }    
    }
};

export{handleClick};